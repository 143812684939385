<template>
  <!-- container -->
  <div id="container">
    <!-- 본문 -->
    <div id="contents" class="wellcare-con">
      <div class="wellcare">
        <!-- 서브 배너 -->
        <section class="subTopArea inner-fixed">
          <span v-html="$t('views.wellcare.banner')"></span>
          <div class="btn-cen">
            <a @click="openTo('https://wellcare.or.kr/ ')" class="button-go-point">{{ $t('views.wellcare.linkButton') }}</a>
          </div>
        </section>

        <!-- //서브 배너 -->
        <section class="subBannerArea">
          <div class="textArea">
            <span v-html="$t('views.wellcare.bannerInfo')"></span>
          </div>
          <div class="subBanner">
              <div class="header-mgroup">
                <span v-html="$t('views.wellcare.subBannerTitle')"></span>
              </div>
              <div class="objectArea">
                <ul>
                  <li v-for="object in objectList" :key="object.id">
                    <div class="object" :style="{ backgroundImage: `url(${require(`@/assets/images/${object.bgImg}.png`)})`, color: object.textColor }">
                      <div class="icon">
                        <!-- <img 
                          :src="require(`@/assets/images/${object.icon}.png`)" 
                          :alt="object.text" 
                          :style="{ width: object.icon_width_web }" 
                        /> -->
                        <img 
                          :src="require(`@/assets/images/${object.icon}.png`)" 
                          :alt="object.text" 
                        />
                      </div>
                      <div class="textArea">{{ object.text }}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="textArea">
                <span v-html="$t('views.wellcare.subBannerInfo')"></span>
              </div>
          </div>
        </section>

        <section :class="`${$_gGet_Locale()} acrylAiTechArea`">
          <div class="areaBox inner-fixed">
            <div class="header-mgroup">
              <span v-html="$t('views.wellcare.acrylAiTechTitle')"></span>
            </div>
            <ul class="owl-carousel owl-aiTeches">
              <li>
                <div class="aiTech-item">
                  <div class="arrow-box">
                    <p v-html="$t('views.wellcare.aiTech01')"></p>
                  </div>
                  <div class="image-box">
                    <img src="@/assets/images/levels-1793-copy.png" alt="aiTech01" />
                  </div>
                </div>
              </li>
              <li>
                <div class="aiTech-item">
                  <div class="arrow-box">
                    <p v-html="$t('views.wellcare.aiTech02')"></p>
                  </div>
                  <div class="image-box">
                    <img src="@/assets/images/levels-1793-copy-2.png" alt="aiTech02" />
                  </div>
                </div>
              </li>
              <li>
                <div class="aiTech-item">
                  <div class="arrow-box">
                    <p v-html="$t('views.wellcare.aiTech03')"></p>
                  </div>
                  <div class="image-box">
                    <img src="@/assets/images/levels-1793-copy-3.png" alt="aiTech03" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section id="join-membership">
          <div class="inner-fixed">
            <div class="image-box">
              <img src="@/assets/images/vector-smart-object.png" alt="wellcare-logo" />
            </div>
            <div class="textArea">
              <h3 class="title"><p v-html="$t('views.wellcare.joinMembershipTitle')"></p></h3>
              <a @click="openTo('https://wellcare.or.kr/assets/files/Korea%20association%20of%20Intelligent%20Wellcare%20Industries_%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85.pdf')" class="button-go-primary">{{ $t('views.wellcare.joinMembershipBtn') }}</a>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- 본문 -->
  </div>
  <!-- //container -->
</template>

<script>
import { owlCarousel, openTo } from '@/assets/js/util.js'
import instance from '@/assets/js/api.js'
import jQuery from 'jquery'

export default {
  data () {
    return {
      objectList: [
        {
          id: 'object1',
          bgImg: 'object_bg_type3',
          bgColor: '#212e96',
          icon: 'object_icon1',
          icon_width_web: '83px',
          text: '신약\n 개발',
          textColor: '#bba9ff'
        },
        {
          id: 'object2',
          bgImg: 'object_bg_type2',
          bgColor: '#005398',
          icon: 'object_icon4',
          icon_width_web: '68px',
          text: '의료 분석\n 시스템',
          textColor: '#00deed'
        },
        {
          id: 'object3',
          bgImg: 'object_bg_type1',
          bgColor: '#1b40a8',
          icon: 'object_icon5',
          icon_width_web: '72px',
          text: '개인화\n 화장품',
          textColor: '#67aafe'
        },
        {
          id: 'object4',
          bgImg: 'object_bg_type3',
          bgColor: '#212e96',
          icon: 'object_icon2',
          icon_width_web: '90px',
          text: '식품공급망\n 구축',
          textColor: '#bba9ff'
        },
        {
          id: 'object5',
          bgImg: 'object_bg_type1',
          bgColor: '#1b40a8',
          icon: 'object_icon3',
          icon_width_web: '95px',
          text: '혼자 케어\n 서비스',
          textColor: '#67aafe'
        }
      ],
      careerList: [],
      isTablet: false,

    }
  },
  methods: {
    openTo (path) { openTo(path) },
  },
  created: function() {
    this.getCareerList()
  },

  mounted: function() {
    if (document.body.clientWidth <= 1200) this.isTablet = true
    if (this.isTablet) {
      jQuery(function ($) {
        $('.owl-aiTeches').on('initialized.owl.carousel changed.owl.carousel', 
          function (e) {
            if (!e.namespace) {
              return;
            }
            var carousel = e.relatedTarget
            $('.slider-counter').text(carousel.relative(carousel.current()) + 1 + ' | ' + carousel.items().length)
          })
        
          let options = {
            items: 1,
            loop: true,
            margin: 0,
            // autoplay: true,
            autoplayTimeout: 2000,
          }
          owlCarousel('.owl-aiTeches', options)
      })
    }
  },
  updated: function() {
    if('careerId' in this.$route.params) {
      this.$nextTick(() => {
        const { careerId } = this.$route.params
        jQuery(function ($) {
          $(`#control-panel-${careerId}`).click()
        })
        scrollTo(`#panel-${careerId}`)
      })
    }
  }
}
</script>
